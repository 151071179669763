import * as _functionsHaveNames2 from "functions-have-names";
var _functionsHaveNames = "default" in _functionsHaveNames2 ? _functionsHaveNames2.default : _functionsHaveNames2;
var exports = {};
var functionsHaveConfigurableNames = _functionsHaveNames.functionsHaveConfigurableNames();
var $Object = Object;
var $TypeError = TypeError;
exports = function flags() {
  if (this != null && this !== $Object(this)) {
    throw new $TypeError("RegExp.prototype.flags getter called on non-object");
  }
  var result = "";
  if (this.hasIndices) {
    result += "d";
  }
  if (this.global) {
    result += "g";
  }
  if (this.ignoreCase) {
    result += "i";
  }
  if (this.multiline) {
    result += "m";
  }
  if (this.dotAll) {
    result += "s";
  }
  if (this.unicode) {
    result += "u";
  }
  if (this.unicodeSets) {
    result += "v";
  }
  if (this.sticky) {
    result += "y";
  }
  return result;
};
if (functionsHaveConfigurableNames && Object.defineProperty) {
  Object.defineProperty(exports, "name", {
    value: "get flags"
  });
}
export default exports;